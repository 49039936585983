.landing-page-content {
    max-width: 960px;
    padding-top: 16px;
    margin: 0 auto;
}

.nellex-main-logo {
    padding-top: 76px;
    width: 50%;
}

.nellex-logo-second-page {
    padding-top: 32px;
    width: 25%;
}

.column-container {
    display: flex;
    justify-content: space-between;
    max-width: 960px;
    margin: 0 auto;
    padding: 16px;
}

#upper {
    margin-top: 32px;
}

.column {
    padding: 4px 16px;
    max-width: 50%;
    text-align: left;
}

.left-column-text {
    font-size: 20px;
    color: white;
}

.left-column-text-other {
    font-size: 16px;
    color: white;
}

.right-column-text {
    font-size: 16px;
    background-position-y: bottom;
}

/* Media query for screens smaller than 600px */
@media (max-width: 640px) {
    .column-container {
        flex-direction: column; /* Stack columns in a column layout */
    }
    .column {
        min-width: 100%; /* Full width for columns */
    }
}

/* Pages styles */

.first-page, .fifth-page {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 001042.png");
}

.second-page {
    padding-top: 16px;
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 002106.png");
}

.third-page {
    padding-top: 16px;
    height: 560px;
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 005807.png");
}

.fourth-page {
    padding-top: 16px;
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 011130.png");
}

.sixth-page {
    padding-top: 16px;
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 002106.png");
}

.seventh-page {
    padding-top: 16px;
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 022345.png");
}

.eighth-page {
    padding-top: 16px;
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 023636.png");
}

.ninth-page {
    padding-top: 16px;
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
}

.tenth-page {
    height: 787px;
    max-width: 80%;
    overflow-x: auto;
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 030436.png");
}

.footer-div {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
}

.image-holder {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    height: 560px;
    white-space: nowrap;
}

.image-item {
    flex: 0 0 auto;
}

#fifth_photo {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    width: 360px;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 015807.png");
}

#second_photo {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    width: 360px;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 012025.png");
}

#third_photo {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    width: 360px;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 012043.png");
}

#fourth_photo {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    width: 360px;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 012006.png");
}

#first_photo {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    width: 360px;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 015830.png");
}

#sixth_photo {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    width: 360px;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 021100.png");
}

#seventh_photo {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    width: 360px;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 021840.png");
}

#ninth_photo {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    width: 360px;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 030859.png");
}

#tenth_photo {
    background-position-y: bottom;
    background-clip: border-box;
    background-size: cover;
    width: 360px;
    background-image: url("../../../public/img/backgrounds/Screenshot 2023-10-08 031001.png");
}

footer {
    background-color: #AAAAAA;
}

a {
    color: inherit;
}